.addmenu-outer{
    margin: 20px 50px;
}
.category-heading{
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    position: relative;
}

.cross-btn{
    cursor: pointer;
}

.cate-btn-outer{
    display: flex;
    justify-content: end;
    align-items: center;
}

.btn{
    background-color: green;
    color: white;
    padding: 10px;
    border-radius: 20px;
    width: fit-content;
}

.btn:hover{
    cursor: pointer;
}
/* ////////////////////popup////////////// */
.popup-form-hidden{
    display: none;
}
.popup-form-show{
    position: absolute;
    top:150px;
    left: 350px;
    height: fit-content;
    width: 500px;
    z-index: 9;
    /* box-shadow: 5px 5px; */
    border: 2px solid black;
    background-color: rgb(212, 216, 219);
}

.popup-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid black;
}

.popup-2{
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.popup-2-input{
    height: 30px;
    width: 300px;
    padding: 10px;
}

.popup-3{
    display: flex;
    justify-content: end;
    height: 60px;
    border-top: 1px solid black;
    align-items: center;
    padding: 20px;
}

.submit-btn{
    cursor: pointer;
    height: fit-content;
    text-align: center;
    background-color: green;
    color: white;
    padding: 10px;
    border-radius: 10px;
    width: 100px;
    font-weight: 700;
    margin-right: 20px;
}

.cancel-btn{
    cursor: pointer;
    height: fit-content;
    text-align: center;
    background-color: rgb(138, 142, 138);
    color: black;

    padding: 10px;
    border-radius: 10px;
    width: 100px;
    font-weight: 700;
}




/* popup///////////////// */

/* /////////category list//////////////// */
.category-list-outer{
    margin-top: 20px;
    border: 1px solid black ;
    padding: 0px 50px;

}

.category-list-heading{
    margin: 0px 20px;
    border-bottom: 1px solid black ;
    margin-bottom: 10px;
}

.table{
    margin-bottom: 10px;
    width: 100%;
}
table,th,td{
    border: 1px solid black;
}

.table-col{
    text-align: center;
    font-size: 18px;
    padding: 10px;
}

.col-1{
    width: 100px;
}

.col-2{
    width: 400px;
}
.col-3{
    display: flex;
    justify-content: space-evenly;
}

.action-icons{
    cursor: pointer;
}
.subcategory-btn{
    background-color: rgb(73, 73, 73);
    color: white;
    padding: 5px;
    border-radius: 10px;
    
}