.popup-f-hidden{
    display: none;
}
.popup-f-show{
    position: absolute;
    top:150px;
    left: 350px;
    height: fit-content;
    width: 500px;
    z-index: 9;
    /* box-shadow: 5px 5px; */
    border: 2px solid black;
    background-color: rgb(212, 216, 219);
}

.p-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid black;
}

.p-2{
    padding: 20px;
}

.form-label{
    font-size: 17px;
    font-weight: 500;
}

.popup-2-input{
    height: 30px;
    width: 300px;
    padding: 10px;
}

.p-3{
    display: flex;
    justify-content: end;
    height: 60px;
    border-top: 1px solid black;
    align-items: center;
    padding: 20px;
}

.submit-btn{
    cursor: pointer;
    height: fit-content;
    text-align: center;
    background-color: green;
    color: white;
    padding: 10px;
    border-radius: 10px;
    width: 100px;
    font-weight: 700;
    margin-right: 20px;
}

.cancel-btn{
    cursor: pointer;
    height: fit-content;
    text-align: center;
    background-color: rgb(138, 142, 138);
    color: black;

    padding: 10px;
    border-radius: 10px;
    width: 100px;
    font-weight: 700;
}


