.home{
    /* margin-left: 300px; */
    color: white;
    height: 100vh;
    width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    background-color: black;
}